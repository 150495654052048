<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.app.bottomMenu.title.editHeadTitle') : $t('cip.plat.app.bottomMenu.title.addHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>

import {getRoleTree} from "@/api/system/role";

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {update, getDetail} from "@/api/system/bottomMenu";
import website from '@/config/website';
import {mapGetters} from "vuex";


export default {
  name: "bottomMenuEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      roleDicData: [],
      dataForm: {},
    }
  },
  computed: {
    ...mapGetters(["language"]),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.app.bottomMenu.field.menuName'),
          prop: 'menuName',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          isGlobal: true, //是否支持国际化
          placeholder: this.$t(`cip.plat.app.bottomMenu.field.menuName`),
          read: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              validator: this.validateName,
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.app.bottomMenu.field.alias'),
          prop: 'alias',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          isGlobal: true, //是否支持国际化
          placeholder: this.$t(`cip.plat.app.bottomMenu.field.alias`),
          read: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              validator: this.validateAlias,
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t("cip.plat.app.bottomMenu.field.roleIds"),
          prop: "roleIds",
          span: 8,
          multiple: true,
          labelWidth: 130,
          type: "tree",
          dicData: [],
          dataType: 'string',
          props: {
            label: "title"
          },
          checkStrictly: true,
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.app.bottomMenu.field.roleIds"),
            trigger: "click"
          }]
        },
        {
          label: this.$t('cip.plat.app.bottomMenu.field.sort'),
          prop: 'sort',
          labelWidth: 130,
          type: 'number',
          minRows: 0,
          maxRows: 100000,
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.app.bottomMenu.field.sort"),
              trigger: "blur"
            },
          ],
          row: true
        },

        {
          label: this.$t('cip.plat.app.bottomMenu.field.filePath'),
          prop: 'filePath',
          type: 'upload',
          labelWidth: 130,
          dataType: 'string',
          loadText: this.$t('cip.plat.app.bottomMenu.field.loadText'),
          propsHttp: {
            url: 'link',
            name: 'originalName',
            res: 'data'
          },
          limit: 1,
          accept: 'image/png, image/jpeg',
          tip: this.$t('cip.cmn.rule.photograph'),
          action: "/api/sinoma-resource/oss/endpoint/put-file-attach-sinoma/platform",
          display: true,
          readonly: !['edit', 'add'].includes(this.type),
          row: true
        },
        {
          label: this.$t('cip.plat.app.bottomMenu.field.brightPath'),
          prop: 'brightPath',
          type: 'upload',
          labelWidth: 130,
          dataType: 'string',
          loadText: this.$t('cip.plat.app.bottomMenu.field.loadText'),
          propsHttp: {
            url: 'link',
            name: 'originalName',
            res: 'data'
          },
          limit: 1,
          accept: 'image/png, image/jpeg',
          tip: this.$t('cip.cmn.rule.photograph'),
          action: "/api/sinoma-resource/oss/endpoint/put-file-attach-sinoma/platform",
          display: true,
          row: true,
          readonly: !['edit', 'add'].includes(this.type),

        },

        {
          label: this.$t('cip.plat.app.bottomMenu.field.remark'),
          labelWidth: 130,
          maxlength: 200,
          prop: 'remark',
          type: 'textarea',
          showWordLimit: true,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.app.bottomMenu.field.remark`),
        },
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData();
    }
  },
  mounted() {
    this.getRoleData(website.tenantId);
  },
  methods: {
    getRoleData(tenantId) {
      getRoleTree(tenantId).then(res => {
        const column = this.findObject(this.formColumn, "roleIds");
        column.dicData = res.data.data;
      });
    },
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    headSave(cancel = false) {


      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          update({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    //国际化字段：App底部菜单名称的校验
    validateName(rule, value, callback) {
      if (value != '') {
        let val = JSON.parse(value)
        let language = this.language
        let val2 = val[language]
        if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
          callback(new Error(this.$t("cip.cmn.rule.deleteWarning")));
        }
        if (val2.length > 30) {
          callback(new Error(this.$t("cip.plat.app.bottomMenu.field.menuName") + this.$t("cip.cmn.rule.nameLength3Warning")));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.app.bottomMenu.field.menuName")));
      }
    },
    //国际化字段：App底部菜单别名的校验
    validateAlias(rule, value, callback) {
      if (value != '') {
        let val = JSON.parse(value)
        let language = this.language
        let val2 = val[language]
        if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
          callback(new Error(this.$t("cip.cmn.rule.deleteWarning")));
        }
        if (val2.length > 30) {
          callback(new Error(this.$t("cip.plat.app.bottomMenu.field.alias") + this.$t("cip.cmn.rule.nameLength3Warning")));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.app.bottomMenu.field.alias")));
      }
    },
  }
}
</script>

<style scoped>

</style>
